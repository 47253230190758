import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Styles from "./covid-19.module.css";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`COVID-19 response kit`}</h2>
    <h3>{`Guidelines for elderly`}</h3>
    <div className={Styles.guidelines}>
    <a className={Styles.guideline} href="https://docs.google.com/document/d/1pEgraE4c0bNnTYsYhilzdascqIRkoOAYXCRPXGky0dc/export?format=pdf">
      Experiencing Symptoms
    </a>
    <a className={Styles.guideline} href="https://docs.google.com/document/d/1jntBsyu3TQY57pA4voDS9ITaQIZ_pub-1ouSor8Hpwc/export?format=pdf">
      Precautionary Measures
    </a>
    <a className={Styles.guideline} href="https://drive.google.com/file/d/1LwejwtsoeyD802rWJWBOBNNk69VDdPnv/view?usp=sharing">
      Psychology Support
    </a>
    <a className={Styles.guideline} href="https://drive.google.com/file/d/1FId_6zXxVig5tmA6k8dQgqGP_XwMslj7/view?usp=sharing">
      Post COVID Management
    </a>
    </div>
    <h3>{`Hand-washing`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1100px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b64ee92f652b63b6fe6e9e08486d9a04/99f37/google-hand-wash-poster.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "127.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAIAAAC+dZmEAAAACXBIWXMAAAsTAAALEwEAmpwYAAADlUlEQVQ4y42U22/cRBSH+/8i9RGJCFEkqAQRAp4AqVX7QkEFqpamSkFtNolImmtz2Vx2N77sZtfrtT22d2yPx3M3ZzehNEsKjMb2eOZ8M2fO+c3cqK8r2ihlGKF5xagyHH6vNbvxzy5TG21kzgY7e2stZ43InlCVqfX/gpUWulZHwXc/Ltx+sPDhYfQ5TAfr/wcsFZWaSQVOik8++/iDuVvzX3z51be3TF1IxWBIqPIaWGleyQSRNiJnuk4xs+bmbt+7+8PqyubN9z7qeDuypqiwEGmVMhaavoENfJjKShFNA8MP+iUumRCi6eScVVxSGh1VUVPX1BhNRAjGV2GZVXIMjZ4X3n8RdiOdoPKX759tL+/wDJ8tzu89/pSfP59ajpnKr8CVxJVMobGxu9+27C5iVitoPGugIGB4HPaW09ArPesvOJt1+2LlIEpX9to4J4Ly9aWOYwfQmSKE+q6UFz5iPgPDykJnfoEPRz2tTS8dHXp2XmZh14kPDiSHcPIm7ocSAp5VCl+BlSYDMrizunBn+fHLwfH99cVvGo9s5I0ePd1/+DN62aC1eeiuLwYOwG+7PcGVLrzcOz23W57dGJzs9TudvtUNhmh5EY+sfGffFfny+a6Vj6kc/+22MRMxZnzA1JjLOiU0yzJMqDA1ZAunKI3DiosgjZeGdtMfJNkwF0MQHHA3QHoQKr94zTQeHa53d5YQLoYn28nJmjYmTsZ+95ySIglD5PZwjJNxENKDgo/A4cmeQV5Y+dlgq//7Pe/BTWG9Cn5633n6ddU9FDzvnjS6x0f901O3tcJJAknGrKcMeGYuA8YNLVAn9934bLNydpPORjq0ROwxMvbs1cBxQ8cdOn/wAgPMZ0QySb0pBI77drvnR8g/r3PfTMd8Z+hajmvZZ8enQmiuQRF4ViTsUiQoCb3tVrjpTA5wSuKl5q/OwEriNEIRY4LrHLQ8A+MLhUHJCvp8w32xXwqpVlpP7jbm19pP4Lya6ShYvlOeUOye92pro93Hwbh0wqYb2HbQYqKaGr4TvtyJknLrtO8MQtBpSWmQBLRilxfUxQZnYAhgKUKpK6FJxcs493HhC0FLWmQFykiitYIhMCAiYG9pe7qa4VQkpUBQM95LWDuizRF5HZYHLv4tLPchtzkfUgk2EUxx/QUIUuWqEIpMjx4hIspYH3rANdDSv1yAk7yaSVVwH2mQPEw1ecOjDNT6IvHmDfAnEzd4238sqVEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Poster on Hand Washing, courtesy Google",
            "title": "Poster on Hand Washing, courtesy Google",
            "src": "/static/b64ee92f652b63b6fe6e9e08486d9a04/99f37/google-hand-wash-poster.png",
            "srcSet": ["/static/b64ee92f652b63b6fe6e9e08486d9a04/5a46d/google-hand-wash-poster.png 300w", "/static/b64ee92f652b63b6fe6e9e08486d9a04/0a47e/google-hand-wash-poster.png 600w", "/static/b64ee92f652b63b6fe6e9e08486d9a04/99f37/google-hand-wash-poster.png 1100w"],
            "sizes": "(max-width: 1100px) 100vw, 1100px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Cough etiquette`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "935px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/81f06497482d34e6f4b9b7dff6672aca/eb390/cdc-cover-your-cough.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "129.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAYAAAC3g3x9AAAACXBIWXMAAa2wAAGtsAGXoLAPAAAErUlEQVRIx31VS28bZRT1b+J/sGPFgqLuWCFQoUICsS6sgNJI7QJEipSgti6oURJkp23qVxQ7fo09Hr8943nYnhm/xjN+H+73+ZGEpr3SyLau53z3nnPu/XxYx2w2w7uib9uYLhf8+3g8xvvCx1/o9+F/+gzhUAhFSUKlUkE0EkFGECBHzpH76DNIP+wgmUrhb78fmXQaUkFCo9HA491dBAOB64DFYhE7D3bwy08/4/tvv8PXd77C7U9uwX/wAtLv+5A++BDSx59j749dPHr4ED/eu4c7X3yJb+7exe1bn+LX+/dhmuYlIIt4LIaTQBA5IQcxLyISDqNtmbBVHelHu1DOk9BbLRwdHiIWjfEnGAwiRp2oDRmz6fQK4HK5+lgs4HkeppQcOQ7kYgmj6QQTynmLOQZEzZL+y/ieEJeLxeJmDlksNqDzOf8cmhYKoShmpg1XVqHkCzeKsFw/bwFuqhw7IwhHQS6GWZehJFJIPPgN5eOTVX7kbl2xXC7fXaFlWZBJNa1loFYqQVOasLpdzNcVlA4DuNj3w9J0zKmLdqsNs9N5C3QLyKzTMjsQ30TQLlc5kK5p6Pd6mBNXrutC3POvqiM+ra6NLuVurJCdOF9zZyYFuIrGv9eqVTTqdXRYNfRyK5FGO53jYnSp0jopr2Xz6FL+GqAzHKJH7bGQw2fQUll4NBkZMnJBFDFwhhiSqmrgFFLwFJU3UeiVGizd4J3VUhkMLPsS0B178OgFbzRCg0QQqDXhPAGHfieTSVxEotCCIfTSef6SEk/CaF9WZVSpi1LlEtAuVSHH4jAEESO7i2G5tgI9DqIQeA3t+BVST/6BQQbuZEU0MgKqtRoXkVXYHwygxVPw+gP4PALQonG4ZGh2UkPIo6OoEF+HoZI4G39KpHzhIolM9AxjsowzdNAlmhoEPGacUhEDmiqfJRTgdSz0icet4nRiSyrDIh9uQgzH+Mz3KKerdCBxy5RndnN6fbQLJSxownyO0Ua/rqBLf2TCKLKCNInB6urRiT0invuUDq6/Cq+myB3BodG0SQjmjSrRZVOFnEMOWG1gQAKUaHWxrcGswsUiOhjo1vwZEc3TGJxGE0PqSNd1GIYBg4bAaKrcTj5XM6CfXazUoiQDM1qrqvRanZbABHWykUsdVC/S6OQl9OQmyrSVeuRNNi0jKmbDNVe5FU9DyxWgyTKGrPV2B7pYhFahiSGxpOeHUGm2O4kMtGQGIllq1L2cElYtA90CjulHM5uDTLapUxVs980IqEm/038+hdXUMKMKNGpRVhQ0Kb9wV1cBK4ABMtW3gAzdpvJtEoVxyCzEJ6imYFAoo6apkIkKjXhqsaVLAuQeP4FNgrFWGRhT/Pr6+v+SYyoXKzBiCciGDjGXpw3T4hPV65honoTgDYaYkic3e2ALeNPmZdEsFCE9O4Aslfg8d9fzauYkCM8PMJnP+KRcvTF9bO0z+YVslt96x0dH26RN16dKvtTpMag6tq6mfbLLyzBsMj1bxqvdvLxeIVsAqWQKL09O8Nfe/jap0kSUy2V+YFUqwqP7RXrxL9RM7v338k2rnMVkMuEPu2fY0mAxJQ4n62vgarCLjXH5HwkVjaFsdKEfAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Cover your cough, courtesy CDC",
            "title": "Cover your cough, courtesy CDC",
            "src": "/static/81f06497482d34e6f4b9b7dff6672aca/eb390/cdc-cover-your-cough.png",
            "srcSet": ["/static/81f06497482d34e6f4b9b7dff6672aca/5a46d/cdc-cover-your-cough.png 300w", "/static/81f06497482d34e6f4b9b7dff6672aca/0a47e/cdc-cover-your-cough.png 600w", "/static/81f06497482d34e6f4b9b7dff6672aca/eb390/cdc-cover-your-cough.png 935w"],
            "sizes": "(max-width: 935px) 100vw, 935px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      